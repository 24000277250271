<template>

  <div class="shopify-wrapper">
    <div :id="whatComponentId()"></div>
  </div>

</template>

<script>
import clientConfigs from '@/mixins/clientConfigs.js'

export default {
  name: 'ButtonShop',
  mixins: [clientConfigs],
  props: ['content', 'id', 'shopid', 'price', 'description', 'image', 'name', 'component'],
  computed:{

    whatButtonContentAdd: function(){
      if (this.$route.params.language == 'fr'){
        return 'Ajouter au panier'
      }else {
        return 'Add to cart'
      }
    },
    whatButtonContentEmpty: function(){
      if (this.$route.params.language == 'fr'){
        return 'Rupture de stock'
      }else {
        return 'Out of stock'
      }
    },
    whatToggleTitle: function(){
      if (this.$route.params.language == 'fr'){
        return 'Panier&nbsp;'
      }else {
        return 'Cart&nbsp;'
      }   
    }
  },
  methods: {
    whatComponentId: function(){
      return 'product-component-' + this.component
    },
    whatUrl: function(){
      return '/fr/boutique/' + this.$route.params.uid
    },

    initShopify: function(){

      if (window.ShopifyBuy) {
        if (window.ShopifyBuy.UI) {
          this.ShopifyBuyInit();
        } else {
          this.loadScript();
        }
      } else {
        this.loadScript();
      }
    },

    loadScript: function(){
      var scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
      // var scriptURL = "http://sdks.shopifycdn.com/buy-button/1.0.0/buybutton.js";
      // var scriptURL = "https://sdks.shopifycdn.com/buy-button/2.1.7/buybutton.js";

      var script = document.createElement('script');
      script.id = 'snipcart-script'
      script.async = true;
      script.src = scriptURL;
      (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
      setTimeout(() => { this.ShopifyBuyInit() }, 800);
    },

    // removeScript(){
    //   let script = document.getElementById('snipcart-script');
    //   script.remove()
    //   window.ShopifyBuy = null
    // },

    ShopifyBuyInit: function(){

      var client = window.ShopifyBuy.buildClient({
        domain: this.$site.shopify.domain,
        storefrontAccessToken: this.$site.shopify.token,
      });

      var ui = window.ShopifyBuy.UI.init(client);

      ui.createComponent('product', {
        id: this.shopid,
        node: document.getElementById(this.whatComponentId()),
        moneyFormat: '%E2%82%AC%7B%7Bamount_with_comma_separator%7D%7D',
        options: {

          product: {
            iframe: false,
            buttonDestination: 'cart',
            contents: {
              img: false,
              title: false,
              price: false,
            },

            text: {
              button: this.whatButtonContentAdd,
              outOfStock: this.whatButtonContentEmpty,
              unavailable: 'Unavailable',
            },
            events: {
              afterRender: function(component){
                let node_button = component.node
                node_button.id = 'snipcart-button'
                // console.log('product afterRender', node_button)
              }

            },
            DOMEvents: {
              'click': function () { // click add to cart

                // let cart = document.querySelector('#snipcart-cart')
                // cart.classList.remove('is-active')
                // cart.classList.remove('is-activeManual')
                // cart.classList.remove('is-visible')
                // let cartContent = document.querySelector('.shopify-buy-frame--cart')
                // cartContent.style.visibility = "visible";

                let buttonShop = document.querySelector('.shopify-buy__btn-wrapper')
                buttonShop.classList.add('is-clicked')
              }
            }
          },

          cart: {
            iframe: false,
            startOpen: false,
            popup: false,

            contents: {
              title: true,
              lineItems: true,
              note: false
            },
            text: {
              title: this.$route.params.language == 'fr' ? 'Panier' : 'Cart',
              empty: this.$route.params.language == 'fr' ? 'Votre panier est vide' : 'Your cart is empty' ,
              button: this.$route.params.language == 'fr' ? 'Commander' : 'Checkout',
              total: 'Total',
              // notice: this.$route.params.language == 'fr' ? 'Frais de port calculés à la commande' : 'Shipping and discount codes are added at checkout',
              notice: this.$route.params.language == 'fr' ? "En confirmant votre commande, vous acceptez les conditions générales de vente" : "En confirmant votre commande, vous acceptez les conditions générales de vente",
            },
            DOMEvents: {
              'click': function(event){
                // let dataElement = event.target.dataset.element
                // if ( dataElement == 'cart.close' || dataElement == 'cart.header'){
                //   let cart = document.querySelector('#snipcart-cart')
                //   cart.classList.toggle('is-activeManual')
                // }

                ////////

                let dataElement = event.target.dataset.element
                if ( dataElement == 'cart.close' || dataElement == 'cart.header'){
                  let cart = document.querySelector('#snipcart-cart')
                  if(cart.classList.contains('is-activeManual')){
                    cart.classList.remove('is-activeManual')
                    cart.classList.remove('is-active')
                    cart.classList.remove('is-visible')
                  }
                }
                
              },
            },
          

            events: {
              afterRender: function(component){
                // console.log('afterRender cart')
                component
                // console.log(component)
                let node_parent = component.node // shopify-buy-cart-wrapper shopify-buy-frame shopify-buy-frame--cart
                let node_container = node_parent.children[0] // .shopify-buy__cart

                // let node_container_header = node_container.children[0]
                // let node_container_scroll = node_container.children[1]
                // let node_container_footer = node_container.children[2]

                node_parent.id = 'snipcart-cart'
                node_container.id = 'snipcart-cart--container'
                // node_container_header.id = 'snipcart-cart--container--header'
                // node_container_scroll.id = 'snipcart-cart--container--scroll'
                // node_container_footer.id = 'snipcart-cart--container--footer'
                // console.log(node_parent.querySelector('.shopify-buy__select-icon'))

                // console.log('CART RENDER', component, component.isEmpty)

                // console.log(node_parent.querySelectorAll('.shopify-buy__quantity-container'))

                let AllDomQuantityNodes = node_parent.querySelectorAll('.shopify-buy__quantity-container')
                let AllDomQuantityTab = Array.prototype.slice.call(AllDomQuantityNodes)

                AllDomQuantityTab.forEach((element) => {
                  // console.log(element.dataset.moved)
                  if (!(element.dataset.moved == 'true')){
                    element.parentNode.insertBefore(element, element.previousElementSibling)
                    element.dataset.moved = 'true'
                  }
                })

                /* BUTTON INCREMENT/DECREMENT*/

                let AllIncrementNodes = node_parent.querySelectorAll('.shopify-buy__quantity-increment span')
                let AllIncrementTab = Array.prototype.slice.call(AllIncrementNodes)

                AllIncrementTab.forEach((element) => {
                  element.innerHTML = "+"
                })

                let AllDecrementNodes = node_parent.querySelectorAll('.shopify-buy__quantity-decrement span')
                let AllDecrementTab = Array.prototype.slice.call(AllDecrementNodes)

                AllDecrementTab.forEach((element) => {
                  element.innerHTML = "-"
                })


                /* CLOSE */

                // let closeButton = node_container.querySelector('.shopify-buy__btn--close').querySelector('.visuallyhidden')
                // console.log(closeButton)
                // if ( this.$route.params.language == 'fr'){
                //   closeButton.innerHTML = 'Fermer le panier'
                // }

                /* CGV */
                let DOM_cgv = node_container.querySelector('.shopify-buy__cart__notice')
                if (DOM_cgv){
                  let Documentlang = document.documentElement.lang
                  let link_cgv = window.location.origin + '/' + Documentlang + '/cgv'
                  let text = Documentlang == 'fr' ? 'En confirmant votre commande, vous acceptez les ' : 'By confirming your order, you accept the '
                  let linktext = Documentlang == 'fr' ? 'conditions générales de vente' : 'general conditions of sale'
                  // DOM_cgv.innerHTML = text + "<a href=" + link_cgv + " target='_blank'>conditions générales de vente</a>."
                  DOM_cgv.innerHTML = text + "<a href=" + link_cgv + " target='_blank'>" + linktext + "</a>." 
                }

              },
              
               // updateItemQuantity: function (cart) {
               //    console.log(cart)
               //    // console.log('updateItemQuantity', cart)
               //    // console.log(cart.isEmpty, cart.lineItems)
               //    // console.log(cart.isEmpty)
               //    // let DOM_cart_scroll = document.querySelector('.shopify-buy__cart-scroll')
               //    // let DOM_cart_scroll_items_input = DOM_cart_scroll.querySelector('.shopify-buy__cart-item__quantity-input')
               //    // DOM_cart_scroll_items_input
               //    // console.log(DOM_cart_scroll)
               //    // console.log(DOM_cart_scroll_items_input)
               //    // setTimeout(function(){ console.log(DOM_cart_scroll_items_input) }, 500);
               //    // setTimeout(function(){ console.log(cart.isEmpty) }, 1000);
               //    // cart.empty();
               // },

            },
          },

          lineItem: {

            contents:{
              image: true,
              title: true,
              variantTitle: this.ClientWidth == 'mobileWidth' ? false : true,
              quantity: true,
              quantityIncrement: true,
              quantityDecrement: true,
              quantityInput: true,
              price: false,
            }
          },

          toggle: {
            iframe: false,
            contents:{
              count: true,
              icon: false,
              title: false,
            },

            templates: {
              // toggle: '<p class="{{data.classes.toggle}}">toooogleeeee</p>',
              count: '<p class="{{data.classes.toggle.count}}">({{data.count}})</p>'
            },

            classes: {
              wrapper: 'test-wrapper',
              toggle: 'toggle',
              count: 'toggle-count',
            },
            styles: {
              count: {
                "background-color": "#61d922",
                color: "red",
              }
            },
            events: {
              afterRender: function(component){
                let node_parent = component.node // .shopify-buy-frame shopify-buy-frame--toggle is-sticky is-active
                node_parent.id = 'snipcart-toggle'
                let node_container = node_parent.children[0]
                let node_count = node_container.children[0]
                let node_title = node_container.children[1]
                node_container.id = 'snipcart-toggle--container'
                node_count.id = 'snipcart-toggle--container--count'
                node_title.id = 'snipcart-toggle--container--title'
              }
            },

            DOMEvents: {
              'click': function () {

                // let cart = document.querySelector('#snipcart-cart')
                // cart.classList.toggle('is-activeManual')

                //////

                let cart = document.querySelector('#snipcart-cart')
                let cartContent = document.querySelector('.shopify-buy-frame--cart')
                // cartContent.style.visibility = "visible";

                if (cart.classList.contains('is-activeManual')){
                  cart.classList.remove('is-active')
                  cart.classList.remove('is-activeManual')
                  cart.classList.remove('is-visible')
                  cartContent.style.visibility = "visible";

                } else if (cart.classList.contains('is-active')){
                  cart.classList.add('is-activeManual')
                  cart.classList.add('is-active')
                  cart.classList.add('is-visible')
                  cart.classList.add('is-initialized')
                  cartContent.style.visibility = "visible";
                } else {
                  cart.classList.add('is-activeManual')
                  cart.classList.add('is-active')
                  cart.classList.add('is-visible')
                  cart.classList.add('is-initialized')
                  cartContent.style.visibility = "visible";
                }
                
              }
            },
            text:{
              title: this.whatToggleTitle
            }
          }
        }
      });
    },

  },
  created(){

  },
  mounted(){
    this.initShopify()

    this.$tools.shopToggleAppear()
    this.$tools.shopCartHide() 

  

    // setTimeout(()=> { 
    //   let selectWrapper = document.querySelector('.shopify-buy__option-select-wrapper')
    //   let options = selectWrapper.querySelectorAll('option')

    //   console.log(selectWrapper)

    //   options[1].setAttribute('selected', '')
    //   options[0].removeAttribute('selected')
      
    //   // setTimeout(()=> { 
    //   //   options[0].setAttribute('selected', '')
    //   //   options[1].removeAttribute('selected')
    //   // }, 1500)
    // }, 1500);
  },

  beforeDestroy(){
    this.$tools.shopToggleHide()
    this.$tools.shopCartHide() 
  }
  
}
</script>

<style lang="scss">

@import '@/css/shopify';

.ButtonShop{

  // position: absolute;
  // bottom: 0px;
  
  display: flex;
  height: 100%;

  font-size: 2.8vw;

  &--lang{
    width: calc(100% / 5 * 2 );
    height: 100%;
    background-color: var(--shop-color-lighten);


    // vertical-align: middle;
    text-align: center;

    display: flex;
    flex-direction: column;
    justify-content: center;

    & p {
      // width: 100%;
    }
  }

  &--buy{
    width: calc(100% / 5 * 3 );
    height: 100%;
  }

}

.snipcart-add-item{
  background-color: var(--shop-color);
  width: 100%;
  height: 100%;
}



</style>