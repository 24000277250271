<template>
  <router-link 
  class="CaseProduct"
  v-bind:to=" '/' + $route.params.language + '/boutique/' + content.uid"
  @mouseenter.native="mouseEnter()"
  @mouseleave.native="mouseLeave()"
  >
  <!-- v-bind:to=" '/' + $route.params.language + '/' + $route.name + '/' + content.uid" -->

    <div class="CaseProduct--img-container" >

      <img class="CaseProduct--img-container--img" 
      v-lazyloading
      :src="content.cover.thumb" 
      :data-srcset="content.cover.srcset"
      sizes="33vw">

    </div>


    <div class="CaseProduct--text-container">

      <div class="CaseProduct--text-container--content">

        <div class="CaseProduct--text-container--content--title">{{content.title_cover}}</div>
        <div class="CaseProduct--text-container--content--price">{{content.price}}€</div>

      </div>

    </div>

    <template v-if="context == '-mobile' ? false: true">

    <transition name="caseProductToggle" mode="out-in">

    <div 
    class="CaseProduct--hover-container"
    v-if="hoverIsVisible">

      <div class="CaseProduct--hover-container--content">
      
        <div class="CaseProduct--hover-container--content--date">{{content.date}}</div> 
        <div class="CaseProduct--hover-container--content--description" v-html="content.description"></div>  

      </div>  

    </div>

    </transition>

    </template>

  </router-link >

</template>

<script>
import LazyLoading from '@/directives/LazyLoading';

export default {
  name: 'CaseProduct',
  directives:{
    LazyLoading
  },
  props: ['content', 'context'],
  data: function(){
    return{
      hoverIsVisible: false
    }
  },
  computed:{


  },
  methods: {
    mouseEnter: function(){
      if (this.context != '-mobile'){
        this.hoverIsVisible = true
      }
    },
    mouseLeave: function(){
      // console.log('mouseLeave')
      this.hoverIsVisible = false      
    }

  },
  created(){
    // console.log(this.context)
  }
  
}
</script>

<style lang="scss">
@import '@/css/variables';

.caseProductToggle-enter{
  transition: transform .3s ease;

  // top: 100vh;
  transform: translate(0, 100%);
}

.caseProductToggle-enter-active {
  transition: transform .3s ease;
  // max-height: 1000px;

  // top: 0vh;
  // position:fixed;
  // height: 100vh;
  // overflow: hidden;
}

.caseProductToggle-enter-to{
  transition: transform .3s ease;
  // top: 0vh;
  transform: translate(0, 0%);
}


.caseProductToggle-leave{
  transition: transform .3s ease;
  // top: 0vh;
  transform: translate(0, 0%);
}


.caseProductToggle-leave-active{
  transition: transform .3s ease;

  // top: 0vh;
  // position:fixed;
  // height: 100vh;
  // overflow: hidden;
}

.caseProductToggle-leave-to{
  transition: transform .3s ease;
  // top: -100vh;
  transform: translate(0, 100%);
}




.CaseProduct{
  position: relative;
  // width: 100%;
  // height: 100%;

  overflow: hidden;
  
  &--img-container{ 

    position: absolute;
    width: 100%;
    height: 100%;

    &--img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

  }

  &--text-container{
    position: absolute;
    z-index: 100;
    width: 100%;
    bottom:0;

    font-size: var(--shop-font-labeur-subtitle);

    &--content{
      padding: var(--esp-big);

      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      &--title{

      }

      &--price {

      }
    }
  }

  &--hover-container{
    width: 100.1%;
    height: 100%;
    position: absolute;

    font-size: var(--shop-font-labeur);

    top: 0%;
    z-index: 99;
    background-color: var(--shop-bg-color);

    overflow: hidden;   

    // transform: scale(1.1); 

    &--content{
      padding: var(--esp-big);
      height: 100%;

      &--date{

        font-family: 'oli';
        margin-bottom: var(--esp-big);
        font-size: var(--shop-font-labeur-subtitle);

      }

      &--description{

        font-family: 'vd-reg';
        line-height: 1.2;

        line-clamp: 10;
        display: -webkit-box;
        -webkit-line-clamp: 10;
        -webkit-box-orient: vertical;  
        overflow: hidden;


        // height: 50%;
        
        & p {
          height: 100%;
          margin-bottom: var(--esp-small);
          text-overflow: ellipsis;
        }

      }

    }
    
  }
}


</style>