<template>

	<transition :name="whatTransition" appear mode="out-in">

	<div class="product" :class="whatConfig">

		<Scrollbar v-if="this.whatComputConfig != '-mobile'" :parent="'boutique-scrollbar'"></Scrollbar>
		<!-- HEADER SHOP COMMUN-->

		<component :is="whatHeader" 
		:contentRedirect="page.redirect"
		:productNumber="productNumber"
		:uid="page.uid"
		:displayRedirect="whatConfig == 'product-mobile' ? false : true "
		:context="'product'"
		></component>

		
		<div class="product--marging" :class="whatConfig"></div>

		<div class="product--newsletter">
			<NewsletterAnimate></NewsletterAnimate>
		</div>

		<!-- MAIN -->
		<div class="product--main" :class="whatConfig">

			<template v-if="whatConfig == 'product-mobile' ? false : true ">

			<div class="product--main--img-container" :class="whatConfig">
				<img class="product--main--img-container--img" 
				v-lazyloading
				:src="page.cover[0].thumb"
				:data-srcset="page.cover[0].srcset"
				sizes="80vw">
			</div>

			</template>

			<template v-else>
				<div class="product--main--img-container" :class="whatConfig">
					<ViewerShop :content="page.gallery"></ViewerShop>
				</div>

				<div class="product--main--text-container--button" :class="whatConfig">
					<ButtonShop 
					class="snipcart-add-item"
					:uid="page.uid"
					:shopid="page.shopId" 
					:price="page.price" 
					:description="page.description" 
					:image="page.cover[0].url"
					:name="page.title"
					:component="page.component">
					</ButtonShop>
				</div>
			</template>

			<div class="product--main--text-container" :class="whatConfig">
				<div class="product--main--text-container--content" :class="whatConfig" ref="text_container">
					<div class="product--main--text-container--content--info" ref="text_info">
						<div class="product--main--text-container--content--info--title" v-html="page.title"></div>
						<div class="product--main--text-container--content--info--date">{{ page.date }}</div>
						<div class="product--main--text-container--content--info--price">{{ page.price }}€</div>			
					</div>

					<div class="product--main--text-container--content--text" 
					ref="text_text" 
					:style="{height: whatConfig != 'product-mobile' ? textHeight + 'px' : ''}" 
					v-html="page.text">
					</div>
				</div>

				<template v-if="whatConfig == 'product-mobile' ? false : true ">

				<div class="product--main--text-container--button" :class="whatConfig">
					<ButtonShop 
					class="snipcart-add-item"
					:uid="page.uid"
					:shopid="page.shopId" 
					:price="page.price" 
					:description="page.description" 
					:image="page.cover[0].url"
					:name="page.title"
					:component="page.component">
					</ButtonShop>
				</div>

				</template>

			</div>

		</div>

		<!-- CONTRIBUTORS -->
		<div class="product--contributors"
		v-if="whatModule('contributors')">
		<!-- v-if="page.modules.includes('contributors')" -->
			<Contributors :content="page.contributors" :context="whatConfig"></Contributors>
		</div>

		<!-- SLIDER DESKTOP / REDIRECT MOBILE -->
		<template v-if="whatConfig == 'product-mobile' ? false : true ">

			<div class="product--slider"
			v-if="whatModule('slider')">
			<!-- v-if="page.modules.includes('slider')" -->
				<ViewerShop :content="page.gallery" ></ViewerShop>
				<!-- <FooterShop :context="'product'"></FooterShop> -->
			</div>
			
		</template>

		<template v-else>
			<RedirectMobile :content="whatContentRedirectMobile()"></RedirectMobile>
			<!-- <FooterShop :context="'product'"></FooterShop> -->
		</template>

		<FooterShop :context="'product'"></FooterShop>

 </div>

 </transition>
  
</template>

<script>
import HeaderShop from '@/components/boutique/HeaderShop.vue'
import HeaderShopMobile from '@/components/boutique/HeaderShopMobile.vue'
import ViewerShop from '@/components/boutique/ViewerShop.vue'
import RedirectMobile from '@/components/boutique/product/RedirectMobile.vue'
import NewsletterAnimate from '@/components/NewsletterAnimate.vue'
import ButtonShop from '@/components/boutique/product/ButtonShop.vue'
import Contributors from '@/components/boutique/product/Contributors.vue'
import FooterShop from '@/components/boutique/FooterShop.vue'

import Scrollbar from '@/components/scrollbar/Scrollbar.vue'

import LazyLoading from '@/directives/LazyLoading';
import clientConfigs from '@/mixins/clientConfigs.js'

export default {
	name: 'product',
	directives:{
		LazyLoading
  },
  mixins: [clientConfigs],
  components: {
    HeaderShop,
    HeaderShopMobile,
    ViewerShop,
    RedirectMobile,
    NewsletterAnimate,
    ButtonShop,
    Contributors,
    FooterShop,
    Scrollbar
  },
	data: function(){
		return {
			state : this.$store.state,
			textHeight: ''
		}
	},

	computed:{
    page: function () { return this.$store.state.[this.$route.params.language].[this.$route.name] },

    whatConfig: function(){

			if (this.ClientFormat == 'portrait'){

				if (this.ClientWidth == 'mobileWidth'){
					return 'product-mobile'
				}else {
					return 'product-portrait'
				}

			}else {
				return ''
			}
    }, 

    whatHeader: function(){

      if (this.ClientWidth == 'mobileWidth'){
        return 'HeaderShopMobile'
      }else {
        return 'HeaderShop'
      }
    },

    productNumber: function(){
			return 3
    },
	},

	methods: {

		whatModule: function(module){
			if(this.page.modules == null){
				return false
			}else {

				if (this.page.modules.includes(module)){
					return true
				}else {
					return false
				}
			}
    },

		whatContentRedirectMobile: function(){

			let redirectMobileContent = []

			this.page.redirect.forEach((element) => {
				if ((redirectMobileContent.length < 6) && (this.page.uid != element.uid) ){
					redirectMobileContent.push(element)
				}
			})
			return redirectMobileContent
		},
		setTextHeight: function(){
			if (this.ClientWidth != 'mobileWidth'){
				let containerHeight = this.$refs.text_container.getBoundingClientRect().height
				let infoHeight = this.$refs.text_info.getBoundingClientRect().height

				this.textHeight = containerHeight - infoHeight - (2/100 * window.innerWidth) 

				// console.log(this.textHeight)
			}else {

				this.textHeight = 'auto'
			}

		},
		onResize: function(){
			this.setTextHeight()
		}

	},

  created() {
		window.scroll(0, 0);

    this.$client.isInit = true
    window.addEventListener('resize', this.onResize)
  },
  mounted(){
			this.setTextHeight()
		},

  beforeDestroy(){
    window.removeEventListener('resize', this.onResize)
    // this.$store.resetProduct(this.$route.params.language)
  }

}

</script>



<style lang="scss">
@import '@/css/variables';

@import '@/css/snipcart';

.product{
  // z-index: 2; // mobile avec  -- not mobile sans

  position: absolute;
  min-height: 100vh;

  width: 100%;
  background-color: var(--shop-bg-color);

  &--main{
		// margin-top: 138px;
		// margin-top: calc(var(--shop-menu-header-height) + var(--shop-menu-redirect-height));
		display: flex;

		height: calc(80vh - var(--shop-menu-redirect-height)); // variables.css 

    & p {
      margin-bottom: var(--esp-small);
    }

		&--img-container{
			width: 65%;

			&--img{
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		&--text-container{
			width: 35%;
			position: relative;

			&--content{
				position: absolute;
				margin: var(--esp-med);

				height: calc(100% - var(--shop-menu-header-height) - ( 2 * var(--esp-med)));

				// overflow: hidden;

				&--info{

					&--title{
						font-family: 'oli';
						font-size: var(--shop-font-labeur-title);
					}

					&--date{
						font-family: 'oli';
						font-size: var(--shop-font-labeur-title);
					}

					&--price{
						font-family: 'oli';
						font-size: var(--shop-font-labeur-title);
						margin-bottom: var(--esp-big);
					}

				}

				&--text{
					margin-top: var(--esp-med);
					font-family: 'vd-reg';
					font-size: var(--shop-font-labeur);

					line-height: 1.1;

					// height: 81%;

					// background-color: red;
					overflow: scroll;
				}
			}
		}
  }
}

.product--marging{
	position: relative;
	width: 100%;
	margin-top: calc(var(--shop-menu-header-height) + var(--shop-menu-redirect-height));
}

.product--marging.product-mobile{
	position: relative;
	width: 100%;
	margin-top: calc(var(--menu-header-height) + var(--shop-menu-header-height));
}

.product--newsletter{
	overflow: hidden;
  position: relative;
  width: 100%;
  height: var(--shop-menu-header-height);
}

.product--main--text-container--button{
	position: absolute;
	width: 100%;
	bottom: 0;
	height: var(--shop-menu-header-height);
	// height: calc( 1.2 * var(--shop-menu-header-height));
	background-color: var(--shop-color);
	z-index: 1;
}

.product--contributors{
	
}

.product--slider{
	width: 100%;
	height: var(--shop-product-slider-height);
}


.product.product-mobile{
	min-height: calc(100% - var(--menu-header-height));
	z-index: 2;
}

.product--main.product-mobile{
	display: block;
	// flex-direction: column;
	height: auto;
	position: relative;
	// margin-top: calc(var(--shop-menu-header-height));
}

.product--main--img-container.product-mobile{
	width: 100%;
	height: 50vh;
	position: relative;

	overflow: hidden;

}

.product--main--text-container.product-mobile{
	width: 100%;
	position: relative;
	// height: 50vh;
}

.product--main--text-container--content.product-mobile{
	width: 100%;
	position: relative;
	height: auto;

	margin: var(--esp-big);
	width: calc(100% - (2 * var(--esp-big)));

}

.product--main--text-container--button.product-mobile{
	position: relative;
}


</style>