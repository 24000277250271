<template>
  <div class="Contributors" :class="context">

    <!-- :class="'product--contributors--3-' + (index + 1)" -->

    <div 
    v-for="(category, index) in content"
    :key="index"
    :class="'product--contributors--' + content.length +'-' + (index + 1) + whatContext">
      <div class="title-list" :class="context">{{category[0].toUpperCase()}}</div>
      
      <!-- <ul class="grid-list" :class="'grid-list-' + (index + 1)"> -->
        <ul :class="whatGridClass(index)">
        <li 
        v-for="(name, index) in category[1]"
        :key="index">
          <p>{{name}}</p>
        </li>
      </ul>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Contributors',
  props: ['content' , 'context'],
  computed:{
    whatContext: function(){
      if (this.context == 'product-mobile'){
        return ' ' + this.context
      }else {
        return ''
      }
    }

  },
  methods: {

    whatGridClass: function(index){

      let whatClass

      if (this.context == "product-mobile"){

        return 'grid-list-1'

      }else {

        if (this.content.length == '2'){
          whatClass = 'grid-list-2'
        }else {
          whatClass = 'grid-list-' + (index + 1) + '-3'
        }

      }
      return whatClass
    }

  }

  
}
</script>

<style lang="scss">
@import '@/css/variables';

.Contributors{
  font-family: 'vd-reg';
  font-size: var(--shop-font-small);
  display: flex;
  margin: var(--esp-med);
}

.title-list{
  // text-align: center;
  margin-bottom: calc(0.5 * var(--esp-small));
  line-height: 1.2;
}


// image / texte
// .grid-list-2{
//   display: grid;
//   grid-template-columns: repeat(3, 1fr);

//   // background-color:red;

//   & li{
//     width: calc((100vw - 2 * var(--esp-med)) / 6);
//     line-height: 1.3;
//     // text-align: center;
//   }
// }


// image / texte / video
// .grid-list-1-3{
//   display: grid;
//   grid-template-columns: repeat(3, 1fr);

//   // background-color:red;

//   & li{
//     width: calc((100vw - 2 * var(--esp-med)) / 7);
//     line-height: 1.3;
//     // padding-right: var(--esp-med);
//     // text-align: center;

//     & p {
//       padding-right: var(--esp-med);
//     }
//   }
// }


// .grid-list-2-3{
//   display: grid;
//   grid-template-columns: repeat(3, 1fr);

//   // grid-auto-flow: row dense;

//   & li{
//     width: calc((100vw - 2 * var(--esp-med)) / 7);
//     line-height: 1.3;
//     // text-align: center;


//     & p {
//       padding-right: var(--esp-med);
//       // background-color: red;
//     } 
//   }
// }


// .grid-list-3-3{
//   display: grid;
//   // grid-template-columns: repeat(1, 1fr);

//   & li{
//     width: calc((100vw - 2 * var(--esp-med)) / 7);
//     line-height: 1.3;
//     // text-align: center;
//     & p {
//       padding-right: var(--esp-med);
//     }
//   }
// }

.grid-list-1{
  column-count: 2;
  & li{
    line-height: 1.3;    
  }
}

.grid-list-2{
  column-count: 3;
  & li{
    line-height: 1.3;
    & p {
      padding-right: var(--esp-med);
    }
  }
}

.grid-list-1-3{
  column-count: 3;
  & li{
    line-height: 1.3;
    & p {
      padding-right: var(--esp-med);
    }
  }
}

.grid-list-2-3{
  column-count: 3;
  & li{
    line-height: 1.3;
    & p {
      padding-right: var(--esp-med);
    } 
  }
}

.grid-list-3-3{
  column-count: 1;
  width: calc((100vw - 2 * var(--esp-med)) / 7);

  & li{
    line-height: 1.3;
    & p {
      padding-right: var(--esp-med);
    }
  }
}

.product--contributors--3-1{
  width: calc((100% - var(--esp-med)) / 7 * 3);
}
.product--contributors--3-2{
  width: calc((100% - var(--esp-med)) / 7 * 3);
  margin-left: var(--esp-med);
}
.product--contributors--3-3{
  width: calc((100% - var(--esp-med)) / 7 * 1);
  margin-left: var(--esp-med);
}

.product--contributors--2-1{
  width: calc((100% - var(--esp-med)) / 6 * 3);
}
.product--contributors--2-2{
  width: calc((100% - var(--esp-med)) / 6 * 3);
  margin-left: var(--esp-med);
}





.Contributors.product-mobile{
  display: block;
  margin: var(--esp-big);
}

.title-list.product-mobile{
  text-align: center;
  margin-top: var(--esp-big);
  margin-bottom: var(--esp-med);
}


.product--contributors--2-1.product-mobile{
  width: 100%;
}
.product--contributors--2-2.product-mobile{
  width: 100%;
}

.product--contributors--3-1.product-mobile{
  width: 100%;
}
.product--contributors--3-2.product-mobile{
  width: 100%;
  margin-left: 0;
}
.product--contributors--3-3.product-mobile{
  width: 100%;
  margin-left: 0;
}
</style>