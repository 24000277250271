<template>
  <div class="RedirectMobile">

    <div class="RedirectMobile--product-container">

      <CaseProduct 
      class="RedirectMobile--product-container--element redirect-mobile"
      v-for="(element, index) in content"
      :key="index"
      :content="element"
      :context="'-mobile'"
      ></CaseProduct>

  </div>

  </div>
</template>

<script>
import CaseProduct from '@/components/boutique/boutique/cardproducts/CaseProduct.vue'

export default {
  name: 'RedirectMobile',
  props: ['content'],
  components: {
    CaseProduct
  },
  computed:{

  },
  methods: {
    // [^10.Entretien téléphonique mené le 9 août 2017.]
  },
  created(){
  }
  
}
</script>

<style lang="scss">

.RedirectMobile{



}

.RedirectMobile{
  color: black;
  width: 100%;
  height: auto;

  &--product-container{

    width: 100%;
    display: flex;
    flex-wrap: wrap;

    &--element{

      width: calc(100% / 2);
      height: calc(100vw / 2);
      
      &--img{

        width: 100%;
        height: 100%;
        object-fit: cover;

      }

      &--text-container{

      }
    }
  }
}



</style>